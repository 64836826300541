import styled from '@emotion/styled';

const InputWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '0px auto 30px',
  width: '100%',
  // maxWidth: '500px',

  '@media (max-width: 599px)': {
    marginBottom: 20,
  },
});

export default InputWrapper;
