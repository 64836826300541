import React from 'react';

import {
  ErrorFallbackProps,
  ErrorMessageProps,
  ErrorMessageVariantProps,
} from '@models/common/error';
import * as colors from '@styles/colors';
import { parseErrorMessage } from '@utils/common';

const FullPageErrorFallback: React.FunctionComponent<ErrorFallbackProps> = ({
  error,
}) => (
  <div
    role="alert"
    css={{
      color: colors.danger,
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <p>Uh oh... There`&apos;`s a problem. Try refreshing the app.</p>
    <pre>{parseErrorMessage(error)}</pre>
  </div>
);

const errorMessageVariants: ErrorMessageVariantProps = {
  stacked: { display: 'block' },
  inline: { display: 'inline-block' },
};

const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({
  error,
  variant = 'stacked',
  ...props
}) => (
  <div
    role="alert"
    css={[
      {
        color: colors.danger,
        textAlign: 'left',
        marginTop: 5,
        fontSize: 18,
        fontWeight: 600,
        letterSpacing: 0.2,
        lineHeight: '30px',
      },
      errorMessageVariants[variant],
    ]}
    {...props}
  >
    {parseErrorMessage(error)}
  </div>
);

export { FullPageErrorFallback, ErrorMessage };
