import React from 'react';
import { isElement } from 'react-is';
import styled from '@emotion/styled';

import { LabelProps } from '@models/common/label';

const InputLabel = styled.label<{
  rounded?: boolean;
  small?: boolean;
  noMargin?: boolean;
}>`
  color: #172643;
  margin-bottom: ${props => (props.noMargin ? '0px' : '10px')};
  font-size: ${props => (props.small ? '11px' : '16px')};
  letter-spacing: 1.14px;
  line-height: ${props => (props.small ? '13px' : '20px')};
  text-transform: uppercase;
  font-weight: 400;
  padding-left: ${props => (props.rounded ? '15px' : '0px')};
`;

const Label: React.FunctionComponent<LabelProps> = ({
  name,
  rounded,
  small,
  noMargin,
  label: LabelComponent,
}) => {
  let labelValue;

  if (typeof LabelComponent === 'string') {
    labelValue = (
      <InputLabel
        noMargin={noMargin}
        rounded={rounded}
        small={small}
        htmlFor={name}
      >
        {LabelComponent}
      </InputLabel>
    );
  } else if (isElement(LabelComponent)) {
    labelValue = LabelComponent;
  } else {
    labelValue = <LabelComponent />;
  }

  return labelValue;
};

export default Label;
