import { format } from 'util';

import { MIN_AGE, MIN_ALLOWED_DATE } from './constants';

const errorMessages = {
  MISSING_INFO: 'Oops! Missing info',
  REQUIRED_FIELD: 'Please enter %s',
  REQUIRED_RADIO_FIELD: 'Please make a selection',
  INVALID_FIELD: 'Invalid %s',
  NON_EXISTENT: '%s does not exist',
  INVALID_CREDENTIALS: 'Invalid Credentials',
  ALREADY_EXISTS: '%s with %s %s already exists.',
  MINIMUM_AGE: `Unfortunately, Brightside is only for guests ${MIN_AGE} years of
  age and older.`,
  FUTURE_DOB: 'Date of birth cannot be in the future',
  OLDEST_DATE: `Date should be no earlier than ${MIN_ALLOWED_DATE}`,
  ACCOUNT_ALREADY_EXISTS:
    'Looks like you already have an account. Please log in.',
  INVALID_INFO: 'Oops! Invalid %s',
};

const errors: {
  [key: string]: (key: string, ...additionalFields: string[]) => string;
} = {
  requiredField: key => format(errorMessages.REQUIRED_FIELD, key),
  invalidField: key => format(errorMessages.INVALID_FIELD, key),
  nonExistentResource: key => format(errorMessages.NON_EXISTENT, key),
  invalidInfo: key => format(errorMessages.INVALID_INFO, key),
  alreadyExists: (key, field, value) =>
    format(errorMessages.ALREADY_EXISTS, key, field, value),
};

export { errors, errorMessages };
